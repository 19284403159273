<template>
  <section class="TeamsTable" :style="{minWidth: '800px'}">
    <div class="TeamsTable__filters">

      <div class="TeamsTable__filters-input-wrapper">
        <div class="input-search-wrapper">
          <input class="input-search" placeholder="Search by name" v-model="nameSearchKey">
        </div>
        <div class="input-search-wrapper">
          <input class="input-search" placeholder="Search by abbreviation" v-model="abbreviationSearchKey">
        </div>

        <Button
            type="link"
            @click="clearFilters"
        >
          Clear filters
        </Button>
      </div>

      <div class="TeamsTable__redirect-btn-wrapper">
        <div class="redirect-btn">
          <Button type="primary" @click="redirectToMainContacts()">
            Back to Main Contact
          </Button>
        </div>
        <div class="redirect-btn">
          <Button type="primary" @click="redirectToDeliveryAddresses()">
            Back to Delivery Contacts
          </Button>
        </div>
      </div>

    </div>

    <Table
        :collection="teamsList"
        :columns="tableColumns"
        @on-sort="onSort"
    >
    </Table>
    <TableControls @setLimit="updateLimit($event)" @selectPage="updatePage($event)"
                   :count="count" :limit="limit" :offset="offset" />
  </section>
</template>

<script>
import Table from '@/components/Table/Table.component';
import TableControls from "@/components/Table/TableControls.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import Button from "@/components/Button/Button.component";

import RouterNames from '@/router/route.names';

import {TableColumnsCollection} from "@/components/Table/classes/TableColumns.collection";
import {TableColumnModel} from "@/components/Table/classes/TableColumn.model";
import get from "lodash.get";

export default {
  name: 'DDMCAF-TeamsTable',
  components: {Table, Button, InputDropdown, TableControls},
  data: () => ({
    nameSearchKey: null,
    abbreviationSearchKey: null,
    tableColumns: [],
  }),
  watch: {
    nameSearchKey(name) {
      if(name) {
        this.getTeams({name})
      }
    },
    abbreviationSearchKey(abbreviation) {
      if(abbreviation) {
        this.getTeams({abbreviation})
      }
    }
  },
  computed: {
    teamsListMeta: {
      get: function () {
        return get(this, '$store.state.teamsList.teamsListMeta')
      },
    },
    teamsList() {
      return get(this, '$store.state.teamsList.teamsList')
    },
    count() {
      return this.teamsListMeta ? this.teamsListMeta.count : 0;
    },
    offset() {
      return this.teamsListMeta ? this.teamsListMeta.offset : 0;
    },
    limit: {
      get: function () {
        return get(this, '$store.state.teamsList.limit')
      },
      set: function (newVal) {
        this.$store.state.teamsList.limit = newVal
      }
    },
    page: {
      get: function () {
        return get(this, '$store.state.teamsList.page')
      },
      set: function (newVal) {
        this.$store.state.teamsList.page = newVal
      }
    },
    pages: {
      get: function () {
        return get(this, '$store.state.teamsList.pages')
      },
      set: function (newVal) {
        this.$store.state.teamsList.pages = newVal
      }
    },
    orderBy: {
      get: function () { return get(this, '$store.state.teamsList.orderBy') },
      set: function (newVal) { this.$store.state.teamsList.orderBy = newVal }
    },
    orderByDirection: {
      get: function () {
        return get(this, '$store.state.teamsList.orderByDirection')
      },
      set: function (newVal) {
        this.$store.state.teamsList.orderByDirection = newVal
      }
    },
  },
  methods: {
    onSort(event) {
      if (event.sort) {
        this.orderBy = event.sort
        this.orderByDirection = this.orderByDirection === 'desc' ? 'asc' : 'desc'
        this.getTeams()
      }
    },
    updateLimit(limit) {
      this.limit = limit;
      this.page = 1;
      this.getTeams()
    },
    updatePage(page) {
      this.page = page;
      this.getTeams()
    },
    getTeams(data) {
      let limit = this.limit
      this.$store.dispatch('teamsList/getTeams', {...data, limit})
    },
    redirectToMainContacts() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.MainContacts;
      this.$router.push({name});
    },
    redirectToDeliveryAddresses() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.TicketRequestRoutes.DeliveryAddress;
      this.$router.push({name});
    },
    clearFilters() {
      if(this.nameSearchKey || this.abbreviationSearchKey) {
        this.nameSearchKey = null
        this.abbreviationSearchKey = null
        this.getTeams()
      }
    },
    screenResizeHandler(e) {
      this.setTableColumnsBasedOnWidth(e.target.outerWidth)
    },
    setTableColumnsBasedOnWidth(width)
    {
      let models = [
        new TableColumnModel('teamId', 'ID', '100px'),
        new TableColumnModel('name', 'Name', '140px', 'localTitle'),
        new TableColumnModel('abbreviation', 'Abbreviation', '140px', 'abbreviation'),
      ]

      if (width > 600) {
        // add logic if needed later
      }

      this.tableColumns = new TableColumnsCollection(models);
    }
  },
  created() {
    this.setTableColumnsBasedOnWidth(window.outerWidth)

    window.addEventListener("resize", this.screenResizeHandler);

    this.getTeams()
  },
}
</script>

<style lang="scss">
.TeamsTable {
  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;


  }

  &__filters-input-wrapper {
    display: flex;

    .input-search-wrapper {
      border-radius: 12px;
      font-weight: 700;
      // TODO: these styles should be moved to a new component. For example: `SearchInput`
      .input-search {
        padding: 12px 16px 12px 16.5px;
        width: 200px;
        font-size: 12px;
        line-height: 17px;
        border-radius: 12px;
        border: 1px solid #D8DAE3;
        font-weight: 700;
        color: #383838;
      }
    }

    .input-search-wrapper:first-child {
      margin-right: 15px;
    }
  }

  &__redirect-btn-wrapper {
    display: flex;

    .redirect-btn:first-child{
      margin-right: 15px;
    }
  }
}
</style>
