<template>
  <section class="MainContacts-View">
    <SpaceDescription>
      <Headline :size="1">List of teams</Headline>
    </SpaceDescription>

    <TeamsTable/>
  </section>
</template>

<script>
import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import TeamsTable from '@/components/Table/tables/TeamsTable.component';

export default {
  components: {
    SpaceDescription,
    Headline,
    TeamsTable,
  }
}
</script>
